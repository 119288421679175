import { VALIDATION_NOT_INITIATED } from 'src/utilities/CommonHelpers';
import { FileValidation } from './MappingInterface';

export enum ButtonActions {
  Export = 'export',
  Import = 'import',
  Reset = 'reset'
}

export enum HeaderValidationMessages {
  ValidHeader = 'Valid Headers',
  InvalidHeader = 'Invalid Headers',
  RejectHeader = 'Unable to validate Headers',
  InitMsg = 'Validating Headers'
}

export enum RequiredFieldsValidationMessages {
  ValuesFound = 'Has all required fields',
  ValuesNotFound = 'Please make sure all required fields are present',
  InitMsg = 'Checking required fields'
}

export enum DuplicateValuesValidationMessages {
  NoDuplicates = 'No duplicates',
  DuplicatesFound = 'Found duplicate records for the required fields',
  APIError = 'Unable to validate the duplicate data. ',
  InitMsg = 'Validating if key column have duplicate records'
}

export const NO_DATA_AVAILABLE_IN_FILE = 'No data available in file';

export const INITIAL_VALIDATION_STATUS: FileValidation = {
  HeadersMatching: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Header validation', colorOverride: 'grey' },
  RequiredFieldMissing: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Required Field validation', colorOverride: 'grey' },
  DuplicateValidation: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Duplicate value validation', colorOverride: 'grey' }
};

export enum EmptyStateMsg {
  Title = 'No Mapping Selected',
  Description = 'Select a mapping from the dropdown to display its data'
}

export const MUTATION_MAPPING_ERROR = 'Unable to submit API request';

export const DUPLICATE_OR_NULL_RECORD_MSG = 'Duplicate/Null Record exists for ';

export const VALIDATION_ERROR_MSG = 'Missing/Invalid values have been marked as blank';

export const OTHERS_APPLICATION_NAME = ['Others - AD3', 'Others - FDA', 'Others - LAB BI', 'Others - PMO'];

export const isActiveValues: string[] = ['Y', 'N'];
export const isActiveBoolValues: boolean[] = [true, false];
export const isActiveStrValues: string[] = ['true', 'false'];
export const stageValues: string[] = ['Prod', 'Non-Prod'];

export const MAPPING_CELL_TIMESTAMP_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const WHITE_SMOKE_COLOR = 'WhiteSmoke';

export const BUTTON_DROPDOWN_ITEMS = [
  { text: 'Export Data', id: 'export', disabled: false },
  { text: 'Import Data', id: 'import', disabled: false },
  { text: 'Reset Table', id: 'reset', disabled: false }
];

// Temporary - To be referred from finsuite_applications dynamo table directly instead
export const pocTeamArr: string[] = ['AD3', 'FDA', 'LAB BI', 'Data Platform'];
