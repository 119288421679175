import React, { useEffect, useState } from 'react';
import { BasicMetricContainer, ErrorContainer, NotInitializedContainer } from '../FinTechOpsCommonElements';
import { Box, ColumnLayout, Container, Header, PieChart, SpaceBetween, Table, Grid, BarChart } from '@amzn/awsui-components-react';
import { FinTechOpsMessages, FinTechOpsMetricsDescriptions } from 'src/components/fintech-ops/FinTechOpsConstants';
import { i18nStringHour } from 'src/components/fintech-ops/i18nStrings';
import { EmptyStateMessage } from 'src/components/fintech-ops/EmptyStateMessage';
import { RestAPI } from '@aws-amplify/api-rest';
import Button from '@amzn/awsui-components-react/polaris/button';
import MixedLineBarChart from '@amzn/awsui-components-react/polaris/mixed-line-bar-chart';
import { L8_MANAGER_DETAILS, RISK_COUNT_TREND_METRICS_FLAGS, RISK_REMEDIATOR_GRAPH_WIDTH_BREAKPOINTS, UNMAPPED_STRING } from './constants';
import { summaryByReporteeColumnDef } from 'src/components/fintech-ops/risk-remediator/RiskRemediatorTableFilterConfig';
import moment from 'moment';
import { fetchSamplingDate } from 'src/components/fintech-ops/risk-remediator/utils';

export function RiskRemediatorMetricCount(props: any) {
  return (
    <Box>
      <SpaceBetween size="m" direction="horizontal" alignItems="center">
        <Box variant="h2" padding={'l'}>
          Risk Details
        </Box>
      </SpaceBetween>
      <div>
        <ColumnLayout columns={6} minColumnWidth={10}>
          <BasicMetricContainer
            title="Total Risks"
            value={props.remediatedRiskData.length + props.openRiskData.length + props.devRemediatedRiskData.length}
            unit=""
            href={''}
            description={FinTechOpsMetricsDescriptions.riskRemediatorTotalRisk}
          />
          <BasicMetricContainer
            title="Open Risks"
            value={props.openRiskData.length}
            unit=""
            href={''}
            description={FinTechOpsMetricsDescriptions.riskRemediatorOpenRisk}
          />
          <BasicMetricContainer
            title="Auto-Remediated"
            value={props.remediatedRiskData.length}
            unit=""
            href={''}
            description={FinTechOpsMetricsDescriptions.riskRemediatorTotalRemediatedRisk}
          />
          <BasicMetricContainer
            title="Developer Remediated Risk"
            value={props.devRemediatedRiskData.length}
            unit=""
            href={''}
            description={FinTechOpsMetricsDescriptions.riskRemediatorDeveloperRemediatedRisk}
          />
          <BasicMetricContainer
            title="Auto-Remediation Rate"
            value={(
              (props.remediatedRiskData.length /
                (props.remediatedRiskData.length + props.openRiskData.length + props.devRemediatedRiskData.length || 1)) *
              100
            ).toPrecision(4)}
            unit="%"
            href={''}
            description={FinTechOpsMetricsDescriptions.riskRemediatorRemediationRate}
          />
          <BasicMetricContainer
            title="ASR Certification Rate"
            value={(
              (props.asrData.filter((obj: Record<string, string>) => obj.application_certification_status == 'CERTIFIED').length /
                (props.asrData.length || 1)) *
              100
            ).toPrecision(4)}
            unit="%"
            href={''}
            description={FinTechOpsMetricsDescriptions.riskRemediatorASRMetrics}
          />
        </ColumnLayout>
      </div>
    </Box>
  );
}

export function riskCountTrend(props: any) {
  var frequency = RISK_COUNT_TREND_METRICS_FLAGS[props.duration].frequency;
  var totalDays = RISK_COUNT_TREND_METRICS_FLAGS[props.duration].totalDays;
  var monthFlag = RISK_COUNT_TREND_METRICS_FLAGS[props.duration].monthFlag;
  var dateFlag = RISK_COUNT_TREND_METRICS_FLAGS[props.duration].dateFlag;
  var yearFlag = RISK_COUNT_TREND_METRICS_FLAGS[props.duration].yearFlag;

  const pollingDateList: string[] = [];
  const barData: any = [];
  const lineData: any = [];
  for (let i = 0; i <= totalDays; i = i + frequency) {
    var pollingDate: string = '';
    var filterDate: string = '';
    var prevFilterDate: string = '';
    var sampleDate = moment().set('date', moment().date() - i);
    var prevFilterDate = fetchSamplingDate(i + frequency, 'Previous');
    var filterDate = fetchSamplingDate(i);
    var openRisk = props.openRiskData.filter(
      (obj: Record<string, string>) => obj.event_timestamp <= filterDate && obj.event_timestamp > prevFilterDate
    ).length;
    var remediatedRisk = props.remediatedRiskData.filter(
      (obj: Record<string, string>) => obj.event_timestamp <= filterDate && obj.event_timestamp > prevFilterDate
    ).length;
    var devRemediatedRisk = props.devRemediatedRiskData.filter(
      (obj: Record<string, string>) => obj.event_timestamp <= filterDate && obj.event_timestamp > prevFilterDate
    ).length;

    if (yearFlag) {
      pollingDate = sampleDate.year().toString();
    }
    pollingDate = ('0' + (sampleDate.month() + 1)).slice(-2).toString() + '/' + pollingDate;
    if (dateFlag) {
      pollingDate = pollingDate + ('0' + sampleDate.date()).slice(-2).toString();
    }

    pollingDateList.push(pollingDate);
    barData.push({ x: pollingDate, y: devRemediatedRisk + openRisk });
    lineData.push({ x: pollingDate, y: remediatedRisk });
  }
  return (
    <BarChart
      series={[
        { title: 'Non-Auto Remediated Risk', type: 'bar', data: barData.reverse() },
        { title: 'Auto Remediated Risk', type: 'bar', data: lineData.reverse() }
      ]}
      xDomain={pollingDateList.reverse()}
      xScaleType="categorical"
      ariaLabel="Risk Trend"
      errorText={FinTechOpsMessages.errorText}
      hideFilter={true}
      i18nStrings={i18nStringHour('Severity')}
      empty={<EmptyStateMessage title={FinTechOpsMessages.emptyStateMessage} subtitle={FinTechOpsMessages.emptyStateMessageDescription} />}
      xTitle="Time Period"
      yTitle="No. of Risks"
      noMatch={FinTechOpsMessages.noMatchMessage}
    />
  );
}
export function riskSummaryByType(props: any) {
  const [categorySummaryData, selectCategorySummaryData] = useState<any>([]);
  if (props.selectedManager.value == 'All') {
    props.selectedManager.value = 'degardm';
  }
  useEffect(() => {
    const aggregatedRiskCategories: Record<string, any> = {};
    const riskList: any[] = [props.openRiskData, props.remediatedRiskData, props.devRemediatedRiskData];
    let riskCategories: string[] = [];
    riskList.forEach((riskData: Record<string, string>[]) => {
      riskCategories = [...new Set(riskData.map((obj: any) => obj.issue_detected))];
      riskCategories.forEach((category: string) => {
        let count: any = riskData.filter((obj: Record<string, string>) => obj.issue_detected == category).length;
        if (category in aggregatedRiskCategories && aggregatedRiskCategories[category]) {
          count = count + aggregatedRiskCategories[category];
        }
        aggregatedRiskCategories[category] = count;
      });
    });
    let pie_data: Record<string, string>[] = [];
    for (let key in aggregatedRiskCategories) {
      pie_data.push({ title: key, value: aggregatedRiskCategories[key] });
    }
    selectCategorySummaryData(pie_data);
  }, [props.openRiskData, props.remediatedRiskData, props.devRemediatedRiskData]);

  return (
    <PieChart
      data={categorySummaryData}
      detailPopoverContent={(datum, sum) => [
        { key: 'Risk count', value: datum.value },
        {
          key: 'Percentage',
          value: `${((datum.value / sum) * 100).toFixed(0)}%`
        }
      ]}
      segmentDescription={(datum, sum) => `${((datum.value / sum) * 100).toFixed(0)}%`}
      ariaDescription="Pie chart showing count and types of risks"
      ariaLabel="Risk Category metrics"
      hideFilter
      hideDescriptions
      hideTitles
      //fitHeight
      empty={
        <Box textAlign="center" color="inherit">
          <b>No data available</b>
        </Box>
      }
      noMatch={
        <Box textAlign="center" color="inherit">
          <b>No matching data</b>
          <Box variant="p" color="inherit">
            There is no matching data to display
          </Box>
          <Button>Clear filter</Button>
        </Box>
      }
    />
  );
}

export function asrMetrics(props: any) {
  const [asrMetricsData, setAsrMetricsData] = useState<any>([]);

  useEffect(() => {
    let certificationPieData: Record<any, any>[] = [];
    const aggregatedAppCategories: Record<string, any> = { certificateClassification: {} };
    let certificationStatus: string = '';
    props.asrData.forEach((asrApp: Record<string, string>) => {
      certificationStatus = asrApp.application_certification_status;
      let count: any = props.asrData.filter((obj: Record<string, string>) => obj.application_certification_status == certificationStatus).length;
      aggregatedAppCategories['certificateClassification'][certificationStatus] = count;
    });

    for (let key in aggregatedAppCategories['certificateClassification']) {
      certificationPieData.push({ title: key, value: aggregatedAppCategories['certificateClassification'][key] });
    }

    setAsrMetricsData(certificationPieData);
  }, [props.asrData]);

  return (
    <PieChart
      data={asrMetricsData}
      detailPopoverContent={(datum, sum) => [
        { key: 'App count', value: datum.value },
        {
          key: 'Percentage',
          value: `${((datum.value / sum) * 100).toFixed(0)}%`
        }
      ]}
      segmentDescription={(datum, sum) => `${((datum.value / sum) * 100).toFixed(0)}%`}
      ariaDescription="Pie chart showing ASR and certification details"
      ariaLabel="ASR certification metrics"
      size="large"
      variant="donut"
      hideFilter
      hideDescriptions
      hideTitles
      //fitHeight
      empty={
        <Box textAlign="center" color="inherit">
          <b>No data available</b>
        </Box>
      }
      noMatch={
        <Box textAlign="center" color="inherit">
          <b>No matching data</b>
          <Box variant="p" color="inherit">
            There is no matching data to display
          </Box>
          <Button>Clear filter</Button>
        </Box>
      }
    />
  );
}

export const riskMetricGraphs = (props: any) => {
  return (
    <Grid
      gridDefinition={[
        { colspan: RISK_REMEDIATOR_GRAPH_WIDTH_BREAKPOINTS },
        { colspan: RISK_REMEDIATOR_GRAPH_WIDTH_BREAKPOINTS },
        { colspan: RISK_REMEDIATOR_GRAPH_WIDTH_BREAKPOINTS }
      ]}
    >
      <Container fitHeight={true}>
        <Header variant="h2">Risk Count Trend</Header> {riskCountTrend(props)}
      </Container>
      <Container fitHeight={true}>
        <Header variant="h2">Risk Summary by Category</Header>
        <br />
        {riskSummaryByType(props)}
      </Container>
      <Container fitHeight={true}>
        <Header variant="h2">ASR Certification Metrics</Header>
        <br />
        {asrMetrics(props)}
      </Container>
    </Grid>
  );
};

export const fetchSummaryDetailsByReports = (props: any) => {
  const [ownerSummaryData, selectOwnerSummaryData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  if (props.selectedManager.value == 'All') {
    props.selectedManager.value = 'degardm';
  }
  //console.log(props.directsData.split(','))
  useEffect(() => {
    const summaryData: Record<string, any>[] = [{}];
    setIsLoading(true);
    let totalCount: Record<string, any> = {
      openRiskCount: 0,
      remediatedRiskCount: 0,
      devRemediatedRiskCount: 0,
      unCertifiedAsrAppCount: 0,
      totalAppCount: 0
    };
    const parentList: string =
      props.selectedManager.value == L8_MANAGER_DETAILS.login ? props.directsData + `,${UNMAPPED_STRING}` : props.directsData;
    parentList.split(',').forEach((reportee: string) => {
      let individualSummary: Record<string, any> = {};
      const openRisk = props.openRiskData.filter(
        (obj: Record<string, string>) =>
          obj.leadership_rollup.split(',').includes(reportee.split(':')[0]) || obj.owner_alias.split(',')[0] == reportee.split(':')[0]
      );
      totalCount.openRiskCount += openRisk.length;
      const remediatedRisk = props.remediatedRiskData.filter(
        (obj: Record<string, string>) =>
          obj.leadership_rollup.split(',').includes(reportee.split(':')[0]) || obj.owner_alias.split(',')[0] == reportee.split(':')[0]
      );
      totalCount.remediatedRiskCount += remediatedRisk.length;
      const devRemediatedRisk = props.devRemediatedRiskData.filter(
        (obj: Record<string, string>) =>
          obj.leadership_rollup.split(',').includes(reportee.split(':')[0]) || obj.owner_alias.split(',')[0] == reportee.split(':')[0]
      );
      totalCount.devRemediatedRiskCount += devRemediatedRisk.length;
      let unCertifiedAsrApp = [];
      let totalAppCount = [];
      if (props.asrData !== undefined) {
        unCertifiedAsrApp = props.asrData.filter(
          (obj: Record<string, string>) =>
            obj.application_certification_status != 'CERTIFIED' &&
            (('leadership_rollup' in obj && obj.leadership_rollup.split(',').includes(reportee.split(':')[0])) ||
              obj.owner_alias == reportee.split(':')[0])
        );
        totalAppCount = props.asrData.filter(
          (obj: Record<string, string>) =>
            ('leadership_rollup' in obj && obj.leadership_rollup.split(',').includes(reportee.split(':')[0])) ||
            obj.owner_alias == reportee.split(':')[0]
        );
      }
      totalCount.unCertifiedAsrAppCount += unCertifiedAsrApp.length;
      totalCount.totalAppCount += totalAppCount.length;
      individualSummary = {
        login: reportee.split(':')[1],
        openRiskCount: openRisk.length,
        remediatedRiskCount: remediatedRisk.length,
        devRemediatedRiskCount: devRemediatedRisk.length,
        unCertifiedAsrAppCount: unCertifiedAsrApp.length,
        totalAppCount: totalAppCount.length
      };
      summaryData.push(individualSummary);
    });

    for (let key in totalCount) {
      totalCount[key] = <b>{totalCount[key]}</b>;
    }
    summaryData.push({ login: <b>Total</b>, ...totalCount });
    setIsLoading(false);

    selectOwnerSummaryData(summaryData);
  }, [props.openRiskData, props.remediatedRiskData, props.devRemediatedRiskData, props.selectedManager.value, props.asrData]);
  //}

  return (
    <Table
      columnDefinitions={summaryByReporteeColumnDef}
      items={ownerSummaryData}
      loading={isLoading}
      loadingText="Loading Reportee details"
      sortingDisabled
      empty="No Data"
      wrapLines
      header={<Header> Risk Summary by Owner </Header>}
    />
  );
};

export const RiskRemediatorOverview = (props: any) => {
  return (
    <div>
      {RiskRemediatorMetricCount(props)}
      <br />
      {fetchSummaryDetailsByReports(props)}
      <br />
      {riskMetricGraphs(props)}
    </div>
  );
};
