import { SplitPanelProps } from '@amzn/awsui-components-react';

export interface riskCountMetricFlag {
  frequency: number;
  totalDays: number;
  monthFlag: boolean;
  dateFlag: boolean;
  yearFlag: boolean;
}

export const DURATION_DATA: Record<string, string>[] = [
  { label: '1M', value: '1M' },
  { label: '3M', value: '3M' },
  { label: '6M', value: '6M' },
  { label: '1Y', value: '1Y' }
];
export const L8_MANAGER_DETAILS: Record<string, string> = { name: 'Michael Degard', login: 'degardm' };
export const RISK_TYPE_FLAG = {
  OPEN: 'Dev-Notify',
  'AUTO-REMEDIATED': 'Remediated',
  'DEV-REMEDIATED': 'DevRemediated'
};
export const RISK_COUNT_TREND_METRICS_FLAGS: Record<string, riskCountMetricFlag> = {
  '1M': {
    frequency: 5,
    totalDays: 30,
    monthFlag: true,
    dateFlag: true,
    yearFlag: false
  },
  '3M': {
    frequency: 15,
    totalDays: 90,
    monthFlag: true,
    dateFlag: true,
    yearFlag: false
  },
  '6M': {
    frequency: 30,
    totalDays: 180,
    monthFlag: true,
    dateFlag: false,
    yearFlag: true
  },
  '1Y': {
    frequency: 60,
    totalDays: 365,
    monthFlag: true,
    dateFlag: false,
    yearFlag: true
  }
};
export const RISK_REMEDIATOR_API_ERROR = 'Risk Remediator API failed to fetch data.Please refresh';
export const RISK_REMEDIATOR_MANAGER_API_ERROR = 'Risk Remediator API failed to fetch manager details.Please refresh';
export const RISK_REMEDIATOR_DIRECTS_API_ERROR = 'Risk Remediator API failed to fetch directs details.Please refresh';
export const USER_GUIDE_URL = 'https://w.amazon.com/bin/view/Risk-Remediator-User-Guide';
export const BEST_PRACTICES_URL = 'https://quip-amazon.com/c4GcA6Wj9rkU/DaS-Fintech-Best-Practices';
export const RISK_TYPE: Record<string, any>[] = [
  {
    label: 'All Risks',
    options: [
      {
        label: 'NAWS',
        value: 'NAWS'
      },
      {
        label: 'MAWS',
        value: 'MAWS'
      }
    ]
  }
];
export const RISK_REMEDIATOR_INITIAL_DATA: Record<any, any> = {
  openRiskData: [],
  remediatedRiskData: [],
  devRemediatedRiskData: [],
  refreshedTime: '',
  selectedManager: L8_MANAGER_DETAILS,
  duration: DURATION_DATA[0].value,
  isLoading: false,
  riskType: RISK_TYPE_FLAG
};

export const RISK_REMEDIATOR_GRAPH_WIDTH_BREAKPOINTS: Record<any, any> = { default: 12, xxs: 12, xs: 12, s: 6, m: 4, l: 4, xl: 4 };

export const SPLIT_PANEL_CONFIG: Record<string, any> = {
  panelWidth: 400,
  maxPanelWidth: 600,
  minPanelWidth: 300,
  position: 'side',
  EMPTY_PANEL_CONTENT: {
    header: '',
    body: 'Select an Application to see its details.'
  }
};

export const ASR_DEFAULT_MESSAGE: Record<string, string> = {
  reviewNotInitiated: 'REVIEW NOT INITIATED',
  dataNotClassified: 'NOT CLASSIFIED',
  noViolationReported: 'No Violation Reported',
  notApplicable: 'N/A'
};

export const UNMAPPED_STRING: string = 'dasfintech-compliance-support: Unmapped';
