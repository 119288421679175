import React from 'react';
import { Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import {
  RISK_REMEDIATOR_DETAILS_FILTERING_PROPERTIES,
  RISK_DETAILS_VISIBLE_CONTENT,
  tableDefs,
  asrColumnDef,
  RISK_REMEDIATOR_ASR_FILTERING_PROPERTIES
} from 'src/components/fintech-ops/risk-remediator/RiskRemediatorTableFilterConfig';
import styles from 'src/components/fintech-ops/risk-remediator/styles.module.scss';
import { RiskDetailsTable } from 'src/components/fintech-ops/risk-remediator/RiskRemediatorTableDef';
import Toggle from '@amzn/awsui-components-react/polaris/toggle';

interface riskRemediatorProps {
  openRiskData: any;
  remediatedRiskData: any;
  devRemediatedRiskData: any;
  isLoading: boolean;
  asrData: any;
  isSplitToggleEnabled: (isSplitPanelOpen: boolean, header: any, body: any) => void;
}
export const RiskRemediatorOpenRisks = (props: riskRemediatorProps) => {
  return (
    <div className={styles.riskDetailsTable}>
      <Container>
        <RiskDetailsTable
          pageSize={10}
          emptyMessage="No Risk data to display"
          Data={props.openRiskData}
          FILTERING_PROPERTIES={RISK_REMEDIATOR_DETAILS_FILTERING_PROPERTIES}
          VISIBLE_CONTENT={RISK_DETAILS_VISIBLE_CONTENT}
          COLUMN_DEFINITIONS={tableDefs}
          headerName="Risk Details"
          isLoading={props.isLoading}
          isSplitToggleEnabled={props.isSplitToggleEnabled}
          shouldEnableSelection={false}
          defaultSortColumnKey={'event_timestamp'}
          dataType={'Risks'}
        />
      </Container>
    </div>
  );
};

export const RiskRemediatorRemediatedRisks = (props: riskRemediatorProps) => {
  return (
    <div className={styles.riskDetailsTable}>
      <Container>
        <RiskDetailsTable
          pageSize={10}
          emptyMessage="No Risk data to display"
          Data={props.remediatedRiskData}
          FILTERING_PROPERTIES={RISK_REMEDIATOR_DETAILS_FILTERING_PROPERTIES}
          VISIBLE_CONTENT={RISK_DETAILS_VISIBLE_CONTENT}
          COLUMN_DEFINITIONS={tableDefs}
          headerName="Risk Details"
          isLoading={props.isLoading}
          isSplitToggleEnabled={props.isSplitToggleEnabled}
          shouldEnableSelection={false}
          defaultSortColumnKey={'event_timestamp'}
          dataType={'Risks'}
        />
      </Container>
    </div>
  );
};

export const RiskRemediatorDeveloperRemediatedRisks = (props: riskRemediatorProps) => {
  return (
    <div className={styles.riskDetailsTable}>
      <Container>
        <RiskDetailsTable
          pageSize={10}
          emptyMessage="No Risk data to display"
          Data={props.devRemediatedRiskData}
          FILTERING_PROPERTIES={RISK_REMEDIATOR_DETAILS_FILTERING_PROPERTIES}
          VISIBLE_CONTENT={RISK_DETAILS_VISIBLE_CONTENT}
          COLUMN_DEFINITIONS={tableDefs}
          headerName="Risk Details"
          isLoading={props.isLoading}
          isSplitToggleEnabled={props.isSplitToggleEnabled}
          shouldEnableSelection={false}
          defaultSortColumnKey={'event_timestamp'}
          dataType={'Risks'}
        />
      </Container>
    </div>
  );
};

export const RiskRemediatorRemediatedToggleRisks = (props: riskRemediatorProps) => {
  const [checked, setChecked] = React.useState(false);
  return (
    <Container
      header={
        <SpaceBetween size="m" direction="horizontal" alignItems="center">
          <Header variant="h2">Remediated Risks</Header>
          <Toggle onChange={({ detail }) => setChecked(detail.checked)} checked={checked}>
            {!checked ? 'Auto Remediated' : 'Manual Remediated'}
          </Toggle>
        </SpaceBetween>
      }
    >
      {checked ? RiskRemediatorDeveloperRemediatedRisks(props) : RiskRemediatorRemediatedRisks(props)}
    </Container>
  );
};

export const RiskRemediatoASRDetails = (props: riskRemediatorProps) => {
  return (
    <div className={styles.riskDetailsTable}>
      <Container fitHeight={true}>
        <RiskDetailsTable
          pageSize={10}
          emptyMessage="No ASR data to display"
          Data={props.asrData}
          FILTERING_PROPERTIES={RISK_REMEDIATOR_ASR_FILTERING_PROPERTIES}
          COLUMN_DEFINITIONS={asrColumnDef}
          headerName="ASR Details"
          isLoading={props.isLoading}
          isSplitToggleEnabled={props.isSplitToggleEnabled}
          shouldEnableSelection={true}
          defaultSortColumnKey={'application_release_date'}
          dataType={'Applications'}
        />
      </Container>
    </div>
  );
};
