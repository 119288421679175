import { MappingRawDetails, MappingParsedDetails } from './MappingInterface';
import { ApplicationFlatTableEntity } from 'src/components/context/AppContextModels';
import { hasNull, validateDuplicate } from './MappingValidations';
import { convertUTCtoUserTimeZoneMoment, getCurrentTime, getTimeDifference } from 'src/utilities/DateTimeUtilities';
import { API } from 'aws-amplify';
import { recordApiRequest } from 'src/analytics/CloudWatchRumClient';
import { logger } from 'src/logger';
import { MUTATION_MAPPING_ERROR, MAPPING_CELL_TIMESTAMP_FORMAT, WHITE_SMOKE_COLOR, stageValues, pocTeamArr } from './MappingConstants';
import { FinTechOpsMessages } from '../FinTechOpsConstants';
import { IRichCellEditorParams } from 'ag-grid-community';

//Function to parse and format the mapping raw data into parsed data
export const getParsedAndFormattedMappingData = (mappingRawDetails: MappingRawDetails): MappingParsedDetails => {
  const response: Record<string, any> = {};
  if (mappingRawDetails.poc_employee_details) {
    response.team_poc_login = getEmployeeDetails(mappingRawDetails.poc_employee_details, 'team_poc_login');
    response.team_poc = getEmployeeDetails(mappingRawDetails.poc_employee_details, 'team_poc');
  }
  return {
    ...response,
    metadata: { last_updated_at: mappingRawDetails.last_updated_at },
    data: formatTimestampColumns(mappingRawDetails.data),
    team_manager: getEmployeeDetails(mappingRawDetails.emp_hierarchy, 'team_manager'),
    team_manager_login: getEmployeeDetails(mappingRawDetails.emp_hierarchy, 'team_manager_login')
  };
};

//Function to convert timestamp columns to 'YYYY-MM-DD HH:mm:ss' format
export const formatTimestampColumns = (mappingData: any): string[] => {
  let formattedData: any[] = [];
  mappingData.forEach((item: any) => {
    if (item.hasOwnProperty('last_updated_at')) {
      item.last_updated_at = convertUTCtoUserTimeZoneMoment(item.last_updated_at).format(MAPPING_CELL_TIMESTAMP_FORMAT);
    }
    if (item.hasOwnProperty('updated_at')) {
      item.updated_at = convertUTCtoUserTimeZoneMoment(item.updated_at).format(MAPPING_CELL_TIMESTAMP_FORMAT);
    }
    if (item.hasOwnProperty('created_at')) {
      item.created_at = convertUTCtoUserTimeZoneMoment(item.created_at).format(MAPPING_CELL_TIMESTAMP_FORMAT);
    }
    formattedData.push(item);
  });
  return formattedData;
};

// Function to fetch employee details like name and alias from input data
export const getEmployeeDetails = (inputData: string, dataKey: string) => {
  return JSON.parse(inputData).map((item: Record<string, any>) => item[dataKey]);
};

// Function to fetch employee names from employee login ids in input data
export const mapEmployeeLogintoEmployeeNames = (employeeData: string, dataKey: string, loginId: string) => {
  let employeeName: string[] = []
  loginId.split(',').forEach((login: string) =>{
    const employeeNameObj: Record<string, any> = JSON.parse(employeeData).find((item: Record<string, any>) => item[dataKey] === login);
    employeeName.push(employeeNameObj[dataKey.replace('_login', '')])
  })
  return employeeName.toString()
};

//Function to retrieve the team name from application name
export const teamMap = (parsedApplicationsList: ApplicationFlatTableEntity[], applicationName: string) => {
  let teamName = '';
  if (applicationName.includes('Others')) {
    //
    teamName = applicationName.split('-')[1].trim();
  } else {
    teamName =
      parsedApplicationsList.find(
        (application: ApplicationFlatTableEntity) => application.isActive && application.applicationName === applicationName
      )?.applicationOwner || '';
  }

  return teamName;
};

//Function to retrieve the application source type from application name
export const appSrcTypeMap = (parsedApplicationsList: ApplicationFlatTableEntity[], applicationName: string) => {
  let appSrcTypeName = '';
  if (applicationName.includes('Others')) {
    appSrcTypeName = applicationName.split('-')[0].trim();
  } else {
    appSrcTypeName =
      parsedApplicationsList.find(
        (application: ApplicationFlatTableEntity) => application.isActive && application.applicationName === applicationName
      )?.toolUI || '';
  }

  return appSrcTypeName;
};

//Function to retrieve the cell editor values for drop down in Ag Grid
export const getCellEditorValuesArr = (
  value: string,
  applicationListArr: string[],
  teamManagerLoginArr: string[],
  teamPocLoginArr: string[],
  isActiveValues: string[],
  isActiveBoolValues: boolean[],
  stageValues: string[]
) => {
  switch (value) {
    case 'applicationListArr':
      return applicationListArr;
    case 'teamManagerLoginArr':
      return teamManagerLoginArr;
    case 'teamPocLoginArr':
      return teamPocLoginArr;
    case 'pocTeamArr':
      return pocTeamArr;
    case 'isActiveValues':
      return isActiveValues;
    case 'isActiveBoolValues':
      return isActiveBoolValues;
    case 'stageValues':
      return stageValues;
    default:
      return [];
  }
};

//Function to retrieve the dependent col values
export const getDependentColFunc = (
  dependentColFunc: string,
  applicationName: string,
  mappingRawEmpHierarchyData: MappingRawDetails,
  parsedApplicationsList: ApplicationFlatTableEntity[]
) => {
  switch (dependentColFunc) {
    case 'teamMap':
      return teamMap(parsedApplicationsList, applicationName);
    case 'teamManagerMap':
      return mapEmployeeLogintoEmployeeNames(mappingRawEmpHierarchyData.emp_hierarchy, 'team_manager_login', applicationName);
    case 'appSrcTypeMap':
      return appSrcTypeMap(parsedApplicationsList, applicationName);
    case 'teamPocMap':
      return mapEmployeeLogintoEmployeeNames(<string>mappingRawEmpHierarchyData.poc_employee_details, 'team_poc_login', applicationName);
    default:
      return [];
  }
};

//Function to parse the column definition
export const parseColumnDefinitionData = (
  parsedColData: any[],
  mappingKey: string,
  teamManagerLoginArr: string[],
  teamPocLoginArr: string[],
  applicationListArr: string[],
  isActiveValues: string[],
  isActiveBoolValues: boolean[]
) => {
  const colList: string[] = [];
  const keyColumn: string[] = [];
  let colDefinitionArr: any = [];
  let isBoolCol = false;
  parsedColData.forEach((item: any) => {
    if (item.mapping_key === mappingKey && item.field_name !== 'key_column') {
      let row: any = {};
      if (item.field_name) {
        row.field = item.field_name;
        row.headerName = item.header_name;
        colList.push(item.field_name);
      }
      if (item.is_editable) {
        row.editable = true;
        row.singleClickEdit = true;
      }
      if (item.cell_editor) {
        row.cellEditor = item.cell_editor;
        row.cellEditorParams = {
          values: getCellEditorValuesArr(
            item.cell_editor_value,
            applicationListArr,
            teamManagerLoginArr,
            teamPocLoginArr,
            isActiveValues,
            isActiveBoolValues,
            stageValues
          ),
          multiSelect: true
        } as unknown as IRichCellEditorParams;
      }
      if (!item.is_editable) {
        row.cellStyle = { backgroundColor: WHITE_SMOKE_COLOR };
      }

      colDefinitionArr.push(row);
      if (item.cell_editor_value === 'isActiveBoolValues') {
        isBoolCol = true;
      }
    } else if (item.mapping_key === mappingKey && item.field_name === 'key_column') {
      keyColumn.push(...item.header_name.split(','));
    }
  });

  return { colList, keyColumn, colDefinitionArr, isBoolCol };
};

//Function to return the array without operational columns
export const getArrWithoutOpsCol = (arr: any[], newRow = false): any => {
  let updatedArr = [];

  if (newRow) {
    updatedArr = arr.map(
      ({ isEdited, newRow, created_at, created_by, last_updated_at, updated_at, updated_by, last_updated_by, ...remainingFields }) => remainingFields
    );
  } else {
    updatedArr = arr.map(
      ({ isEdited, created_at, created_by, last_updated_at, updated_at, updated_by, last_updated_by, ...remainingFields }) => remainingFields
    );
  }

  return updatedArr;
};

//Function to validate Duplicate and Null records for key columns
export const validateDuplicateAndNull = async (rowData: any[], newRow: boolean, keyColumn: string[]) => {
  const isValid = (await validateDuplicate(getArrWithoutOpsCol(rowData, newRow), keyColumn)).validationStatus !== 'success';

  const isNull = hasNull(getArrWithoutOpsCol(rowData, newRow), keyColumn);
  return { isValid, isNull };
};

//Function to fetch API data and parsing it to json records
export const fetchAppSyncAPIData = async (queryName: any, queryVar: string, mappingKey: any, queryPayloadPrefix: any, variables?: {}) => {
  try {
    const start = getCurrentTime();
    const mappingAPIResponse: any = await API.graphql({
      query: queryName,
      variables: variables
    });
    const elapsed = getTimeDifference(start);
    logger.info(`Fetched ${queryVar} Data in ${elapsed} ms`);
    recordApiRequest(`${queryVar} ${mappingKey}`, elapsed);
    const parsedColData = JSON.parse(mappingAPIResponse.data[queryVar][queryPayloadPrefix]['data']);
    return parsedColData;
  } catch (error: any) {
    logger.error(`${FinTechOpsMessages.APIError} for ${mappingKey}`, error);
  }
};

//Mutation function for mapping table to create or update the records in RDS table
export const createOrUpdateMapping = async (mutationName: any, mutationVar: string, mappingKey: any, queryPayloadPrefix: any, variables?: {}) => {
  try {
    const start = getCurrentTime();
    const createORupdateResponse: any = await API.graphql({
      query: mutationName,
      variables: variables
    });
    const elapsed = getTimeDifference(start);

    logger.info(`Updated ${mutationVar} Data for ${mappingKey} in ${elapsed} ms`);
    recordApiRequest(`${mutationVar} ${mappingKey}`, elapsed);
    return createORupdateResponse.data[mutationVar][queryPayloadPrefix];
  } catch (error: any) {
    logger.error(`${MUTATION_MAPPING_ERROR} for ${mappingKey}`, error);
  }
};
