import { Link, ProgressBar, PropertyFilterProps } from '@amzn/awsui-components-react';
import React from 'react';
import { ASR_DEFAULT_MESSAGE } from 'src/components/fintech-ops/risk-remediator/constants';
import { fetchOpenRisksForAsrApp } from 'src/components/fintech-ops/risk-remediator/utils';

//_______________________________________________

export const RISK_REMEDIATOR_DETAILS_FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    propertyLabel: 'Owner',
    key: 'owner_alias',
    groupValuesLabel: 'Owner Values',
    operators: ['=', '!=']
  },
  {
    propertyLabel: 'Resource name',
    key: 'resource_arn',
    groupValuesLabel: 'Resource Values',
    operators: ['=', '!=']
  },
  {
    propertyLabel: 'Manager',
    key: 'manager_alias',
    groupValuesLabel: 'Manager Values',
    operators: ['=', '!=']
  },
  {
    propertyLabel: 'Issue Details',
    key: 'issue_detected',
    groupValuesLabel: 'Issue details',
    operators: ['=', '!=']
  }
];

export const RISK_DETAILS_VISIBLE_CONTENT = [
  'resource_arn',
  'resource_type',
  'issue_detected',
  'event_timestamp',
  'owner_alias',
  'manager_alias',
  'action_taken'
];

export const tableDefs = [
  {
    id: 'issue_detected',
    header: 'Issue Details',
    cell: (e: { issue_detected: any }) => e.issue_detected,
    sortingField: 'issue_detected',
    minWidth: 400
  },
  {
    id: 'action_taken',
    header: 'Remediation Details',
    cell: (e: any) => e.action_taken || e.pending_action,
    minWidth: 250
  },
  {
    id: 'owner_alias',
    header: 'Owner',
    cell: (e: { owner_alias: any }) => e.owner_alias,
    sortingField: 'owner_alias'
  },
  {
    id: 'manager_alias',
    header: 'Manager',
    cell: (e: { manager_alias: any }) => e.manager_alias,
    sortingField: 'manager_alias'
  },
  {
    id: 'resource_arn',
    header: 'Resource Name',
    cell: (e: { resource_arn: any }) => e.resource_arn,
    sortingField: 'resource_arn',
    isRowHeader: true,
    minWidth: 400
  },
  {
    id: 'resource_type',
    header: 'Resource Type',
    cell: (e: { resource_type: any }) => e.resource_type,
    sortingField: 'resource_type'
  },
  {
    id: 'event_timestamp',
    header: 'Event timestamp',
    cell: (e: { event_timestamp: any }) => e.event_timestamp,
    sortingField: 'event_timestamp',
    minWidth: 200
  }
];

export const summaryByReporteeColumnDef = [
  {
    id: 'login',
    header: 'Owner',
    cell: (item: any) => item.login,
    isRowHeader: true,
    minWidth: 250
  },
  {
    id: 'openRiskCount',
    header: 'Open Risk Count',
    cell: (item: any) => item.openRiskCount,
    maxWidth: 100
  },
  {
    id: 'remediatedRiskCount',
    header: 'Auto Remediated Risk Count',
    cell: (item: any) => item.remediatedRiskCount,
    maxWidth: 100
  },
  {
    id: 'devremediatedRiskCount',
    header: 'Developer Remediated Risk Count',
    cell: (item: any) => item.devRemediatedRiskCount,
    maxWidth: 100
  },
  {
    id: 'asrAppCount',
    header: 'Total ASR app Count',
    cell: (item: any) => item.totalAppCount,
    maxWidth: 100
  },
  {
    id: 'unCertifiedAsrAppCount',
    header: 'Uncertified App Count',
    cell: (item: any) => item.unCertifiedAsrAppCount,
    maxWidth: 100
  }
];

export const asrColumnDef = [
  {
    id: 'applicationName',
    header: 'Application Name',
    cell: (item: any) => (
      <Link external href={`https://asr.security.amazon.dev/applications/${item.application_id}/details`}>
        {' '}
        {item.asr_application_name}{' '}
      </Link>
    ),
    isRowHeader: true,
    minWidth: 350,
    sortingField: 'asr_application_name'
  },
  {
    id: 'reviewOwner',
    header: 'Review Owner',
    cell: (item: any) => item.asr_review_owner,
    sortingField: 'asr_review_owner',
    minWidth: 200
  },
  {
    id: 'appOwner',
    header: 'ASR Point Of Contact',
    cell: (item: any) => item.asr_app_owner,
    sortingField: 'asr_app_owner',
    minWidth: 200
  },
  {
    id: 'manager',
    header: 'Application Manager',
    cell: (item: any) => item.application_owner,
    sortingField: 'application_manager',
    minWidth: 200
  },
  {
    id: 'certificationStatus',
    header: 'Certification Status',
    cell: (item: any) => item.application_certification_status.replaceAll('_', ' '),
    sortingField: 'application_certification_status',
    minWidth: 300
  },
  {
    id: 'applicationCreationDate',
    header: 'ASR Creation Date',
    cell: (item: any) => item.application_creation_date,
    sortingField: 'application_creation_date',
    minWidth: 200
  },
  {
    id: 'releaseDate',
    header: 'Release Date',
    cell: (item: any) => item.application_release_date || ASR_DEFAULT_MESSAGE.notApplicable,
    sortingField: 'application_release_date',
    minWidth: 200
  },
  {
    id: 'dataClassification',
    header: 'Data Classification',
    cell: (item: any) => item.data_classification || ASR_DEFAULT_MESSAGE.dataNotClassified,
    sortingField: 'data_classification',
    minWidth: 300
  }
];

export const RISK_REMEDIATOR_ASR_FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    propertyLabel: 'Owner',
    key: 'owner_alias',
    groupValuesLabel: 'Owner Values',
    operators: ['=', '!=']
  },
  {
    propertyLabel: 'Application name',
    key: 'asr_application_name',
    groupValuesLabel: 'Application Values',
    operators: ['=', '!=']
  },
  {
    propertyLabel: 'Manager',
    key: 'manager_alias',
    groupValuesLabel: 'Manager Values',
    operators: ['=', '!=']
  },
  {
    propertyLabel: 'Certification Status',
    key: 'application_certification_status',
    groupValuesLabel: 'Application Certification Values',
    operators: ['=', '!=']
  },
  {
    propertyLabel: 'Certification Status',
    key: 'application_certification_status',
    groupValuesLabel: 'Application Certification Values',
    operators: ['=', '!=']
  },
  {
    propertyLabel: 'Certification Status',
    key: 'application_certification_status',
    groupValuesLabel: 'Application Certification Values',
    operators: ['=', '!=']
  },
  {
    propertyLabel: 'Review Progress Status',
    key: 'review_progress_percentage',
    groupValuesLabel: 'Review Progress Status Values',
    operators: ['=', '!=']
  },
  {
    propertyLabel: 'Review Classification',
    key: 'review_classification',
    groupValuesLabel: 'Review Classification Values',
    operators: ['=', '!=']
  }
];
